const gql = String.raw;

export const RETRIEVE_APP_SUBSCRIPTION = gql`
	query ShopifyAppSubscriptionList($id: ID!) {
		node(id: $id) {
			... on AppSubscription {
				id
				name
				status
			}
		}
	}
`;

export const BASIC_PRODUCT_QUERY = gql`
	query ShopifyProductList($first: Int = 1, $after: String, $searchQuery: String = "") {
		products(first: $first, query: $searchQuery, after: $after, sortKey: TITLE) {
			...list
		}
	}

	fragment list on ProductConnection {
		nodes {
			label: title
			value: id
			defaultCursor
		}
		pageInfo {
			hasNextPage
			endCursor
		}
	}
`;

export const COLLECTION_BY_PRODUCT_QUERY = gql`
	query ShopifyProductList($first: Int = 20, $after: String, $searchQuery: String = "") {
		products(first: 1, query: $searchQuery) {
			...list
		}
	}
	fragment list on ProductConnection {
		nodes {
			id
			collections(first: $first, after: $after) {
				nodes {
					title
					id
				}
				pageInfo {
					hasNextPage
					endCursor
				}
			}
		}
	}
`;

export const BASIC_PRODUCT_VARIANT_QUERY = gql`
	query ShopifyProductVariantList($first: Int = 1, $after: String, $searchQuery: String = "") {
		productVariants(first: $first, query: $searchQuery, after: $after, sortKey: TITLE) {
			...list
		}
	}

	fragment list on ProductVariantConnection {
		nodes {
			label: title
			value: id
			sku
			defaultCursor
		}
		pageInfo {
			hasNextPage
			endCursor
		}
	}
`;

export const BASIC_COLLECTION_QUERY = gql`
	query ShopifyCollectionList($first: Int = 1, $after: String, $searchQuery: String = "") {
		collections(first: $first, query: $searchQuery, after: $after, sortKey: TITLE) {
			...list
		}
	}

	fragment list on CollectionConnection {
		nodes {
			label: title
			value: id
		}
		pageInfo {
			hasNextPage
			endCursor
		}
	}
`;

export const DISCOUNT_CODE_GET_QUERY = gql`
	query codeDiscountNodeByCode($code: String!) {
		codeDiscountNodeByCode(code: $code) {
			codeDiscount {
				__typename
				... on DiscountCodeBasic {
					codeCount
					shortSummary
				}
			}
			id
		}
	}
`;

export const SHIPPING_QUERY = gql`
	mutation discountCodeFreeShippingCreate($freeShippingCodeDiscount: DiscountCodeFreeShippingInput!) {
		discountCodeFreeShippingCreate(freeShippingCodeDiscount: $freeShippingCodeDiscount) {
			userErrors {
				field
				message
			}
			codeDiscountNode {
				id
			}
		}
	}
`;

export const BASIC_QUERY = gql`
	mutation discountCodeBasicCreate($basicCodeDiscount: DiscountCodeBasicInput!) {
		discountCodeBasicCreate(basicCodeDiscount: $basicCodeDiscount) {
			userErrors {
				field
				message
			}
			codeDiscountNode {
				id
			}
		}
	}
`;

export const SHIPPING_DISCOUNT_UPDATE_QUERY = gql`
	mutation discountCodeFreeShippingUpdate($freeShippingCodeDiscount: DiscountCodeFreeShippingInput!, $id: ID!) {
		discountCodeFreeShippingUpdate(freeShippingCodeDiscount: $freeShippingCodeDiscount, id: $id) {
			userErrors {
				field
				message
			}
			codeDiscountNode {
				id
			}
		}
	}
`;

export const BASIC_DISCOUNT_UPDATE_QUERY = gql`
	mutation discountCodeBasicUpdate($basicCodeDiscount: DiscountCodeBasicInput!, $id: ID!) {
		discountCodeBasicUpdate(basicCodeDiscount: $basicCodeDiscount, id: $id) {
			userErrors {
				field
				message
			}
			codeDiscountNode {
				id
			}
		}
	}
`;

export const WEBHOOK_LIST = gql`
	query {
		webhookSubscriptions(first: 100) {
			edges {
				node {
					topic
				}
			}
		}
	}
`;

export const WEB_PIXEL_CREATE = gql`
	mutation ($webPixel: WebPixelInput!) {
		webPixelCreate(webPixel: $webPixel) {
			userErrors {
				field
				message
			}
			webPixel {
				id
				settings
			}
		}
	}
`;

export const WEB_PIXEL_DELETE = gql`
	mutation webPixelDelete($id: ID!) {
		webPixelDelete(id: $id) {
			userErrors {
				field
				message
			}
		}
	}
`;

export const WEB_PIXEL_GET = gql`
	query getWebPixel {
		webPixel {
			id
			settings
		}
	}
`;

export const WEB_PIXEL_UPDATE = gql`
	mutation webPixelUpdate($id: ID!, $webPixel: WebPixelInput!) {
		webPixelUpdate(id: $id, webPixel: $webPixel) {
			userErrors {
				field
				message
			}
		}
	}
`;
