import { gql } from '@voyage-lab/cube';

export const BRAND_LIST = gql`
	query GetBrandList($limit: Int, $offset: Int, $search: String, $timezone: String) {
		cube(
			timezone: $timezone
			limit: $limit
			offset: $offset
			where: {
				brands: {
					name: { contains: [$search] }
					# is_enabled: {
					# 	equals: $is_enabled
					# }
					# total_earnings: { gt: 0 }
				}
			}
			orderBy: { brands: { total_earnings: desc } }
		) {
			brands {
				id
				name
				domain
				tenant_id
				total_earnings
			}
		}
	}
`;

export const TOP_BRAND_LIST = gql`
	query GetTopBrandList($limit: Int, $offset: Int, $orderBy: RootOrderByInput, $timezone: String) {
		cube(
			timezone: $timezone
			limit: $limit
			offset: $offset
			orderBy: $orderBy
			where: { subscriptions: { status: { equals: "active" } } }
		) {
			orders {
				aov
				total_amount
			}
			brands {
				id
				name
				domain
				created_at {
					value
				}
			}
			plans {
				name
			}
		}
		meta: cube(orderBy: $orderBy, where: { subscriptions: { status: { equals: "active" } } }, timezone: $timezone) {
			brands {
				count
			}
		}
	}
`;

export const ALL_BRAND_INFO_LIST = gql`
	query GetAllBrandInfoList(
		$limit: Int
		$offset: Int
		$orderBy: RootOrderByInput
		$isOrderByStats: Boolean = false
		$timezone: String
		$where: RootWhereInput
	) {
		cube(timezone: $timezone, limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
			brands {
				domain
				id
				lead_value
				industry
				is_enabled
				name
				tenant_id
				yearly_revenue
				created_at_utc {
					value
				}
			}
			brand_subscription {
				gateway
				plan_name
				status
				usage_charge_percentage
			}
			brand_stats @include(if: $isOrderByStats) {
				total_channel
				total_conversation
				total_recovered
				total_recovered_amount
				total_recovered_orders
				total_recurring_charges
				total_sales_amount
				total_replied_conversation
				total_subscription_charges
				total_unsubscribed_channel
				total_usage_charges
			}
		}
	}
`;

export const BRAND_INFO = gql`
	query GetBrandInfo($id: String, $renewQuery: Boolean) {
		cube(where: { brands: { id: { equals: $id } } }, renewQuery: $renewQuery) {
			leads {
				created_at {
					value
				}
				source_name
				source_type
				store_type
				# extra_data
			}
			brands {
				domain
				id
				lead_value
				industry
				is_enabled
				name
				tenant_id
				yearly_revenue
				created_at_utc {
					value
				}
			}
			tenants {
				email
				name
				phone
				type
			}
			brand_subscription {
				id
				plan_name
				status
				gateway
				usage_charge_percentage
			}
		}
	}
`;

export const ALL_BRAND_STATS_LIST = gql`
	query GetAllBrandStatsList($ids: [String], $timezone: String, $currDateRange: [String], $prevDateRange: [String]) {
		curr: cube(
			timezone: $timezone
			where: { brands: { id: { in: $ids } }, brand_stats: { created_at: { inDateRange: $currDateRange } } }
		) {
			brands {
				id
				created_at_utc {
					value
				}
				aov
			}
			brand_stats {
				total_channel
				total_conversation
				total_recovered
				total_recovered_amount
				total_recovered_orders
				total_recurring_charges
				total_sales_amount
				total_replied_conversation
				total_subscription_charges
				total_unsubscribed_channel
				total_usage_charges
			}
		}
		prev: cube(
			timezone: $timezone
			where: { brands: { id: { in: $ids } }, brand_stats: { created_at: { inDateRange: $prevDateRange } } }
		) {
			brands {
				id
				prev_aov: aov
			}
			brand_stats {
				prev_total_conversation: total_conversation
				prev_total_recovered: total_recovered
				prev_total_recovered_amount: total_recovered_amount
				prev_total_replied_conversation: total_replied_conversation
				prev_total_channel: total_channel
				prev_total_unsubscribed_channel: total_unsubscribed_channel
				prev_total_sales_amount: total_sales_amount
			}
		}
	}
`;

export const DAILY_STAT_LIST = gql`
	query GetDailyStatList(
		$limit: Int
		$offset: Int
		$orderBy: RootOrderByInput
		$where: RootWhereInput
		$isHourly: Boolean = false
		$isDaily: Boolean = false
		$isWeekly: Boolean = false
		$isMonthly: Boolean = false
		$isYearly: Boolean = false
		$timezone: String
	) {
		cube(timezone: $timezone, limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
			brand_stats {
				total_subscription_charges
				total_unsubscribed_channel
				total_usage_charges
				total_sales
				total_sales_amount
				total_channel
				total_conversation
				total_recovered
				total_recovered_amount
				total_recovered_orders
				total_recurring_charges
				total_replied_conversation
				created_at {
					hour @include(if: $isHourly)
					day @include(if: $isDaily)
					week @include(if: $isWeekly)
					month @include(if: $isMonthly)
					year @include(if: $isYearly)
				}
			}
		}
	}
`;

export const BRAND_ATTRIBUTES = `
	query GetBrandAttributesList(
		$limit: Int
		$offset: Int
		$orderBy: RootOrderByInput
		# $dateRange: [String]
		$timezone: String
	) {
		cube(
			timezone: $timezone
			limit: $limit
			offset: $offset
			orderBy: $orderBy # where: {
			# }
		) # 	brands: { created_at: { inDateRange: $dateRange } }
		{
			# brands {
			# 	name
			# 	domain
			# }
			brand_attributes {
				aov
				thirty_day_abandoned_cart
				thirty_day_conversations
				thirty_day_earnings
				thirty_day_recovered_orders
				thirty_day_recovered_rev
				total_conversations
				total_earnings
				total_recovered_orders
				total_recovered_rev
				brand_id
				count
				lifetime_revenue
				one_year_recovered_rev
				prev_day_conversations
				seven_day_abandoned_cart
				seven_day_conversations
				seven_day_earnings
				seven_day_recovered_orders
			}
		}
	}
`;

export const BRAND_INVOICE_LIST = gql`
	query GetBrandInvoiceList(
		$brandId: String
		$orderBy: RootOrderByInput
		$limit: Int
		$offset: Int
		$dateRange: [String]
		$timezone: String
		$invoiceId: String
		$gateway: String
	) {
		cube(
			timezone: $timezone
			limit: $limit
			offset: $offset
			where: {
				subscription_charges: {
					brand_id: { equals: $brandId }
					created_at: { inDateRange: $dateRange }
					AND: [{ status: { notEquals: "in_review" } }]
				}
				invoices: { id: { equals: $invoiceId } }
				subscription_payment_source: { gateway: { equals: $gateway } }
			}
			orderBy: $orderBy
		) {
			subscription_charges {
				earnings
				type
				status
				created_at {
					day
				}
				invoice_pdf
			}
			subscription_payment_source {
				gateway
			}
			plans {
				id
				name
			}
			subscriptions {
				status
			}
		}
		meta: cube(
			timezone: $timezone
			where: {
				subscription_charges: {
					brand_id: { equals: $brandId }
					created_at: { inDateRange: $dateRange }
					AND: [{ status: { notEquals: "in_review" } }]
				}
			}
		) {
			subscription_charges {
				count
			}
		}
	}
`;

export const BRAND_INVOICE_GROUPED_LIST = gql`
	query GetBrandInvoiceGroupedList(
		$brandId: String
		$orderBy: RootOrderByInput
		$limit: Int
		$offset: Int
		$dateRange: [String]
		$timezone: String
	) {
		cube(
			timezone: $timezone
			limit: $limit
			offset: $offset
			# total: true
			where: {
				subscription_charges: {
					brand_id: { equals: $brandId }
					created_at: { inDateRange: $dateRange }
					status: { in: ["pending", "complete"] }
					AND: [{ status: { notEquals: "in_review" } }]
				}
			}
			orderBy: $orderBy
		) {
			plans {
				id
				name
			}
			subscriptions {
				status
			}
			subscription_charges {
				earnings
				invoice_pdf
				status
				charge_collected_at {
					month
				}
			}
			invoices {
				id
			}
			subscription_payment_source {
				gateway
			}
		}
		meta: cube(
			timezone: $timezone
			where: {
				subscription_charges: {
					brand_id: { equals: $brandId }
					created_at: { inDateRange: $dateRange }
					status: { in: ["pending", "complete"] }
					AND: [{ status: { notEquals: "in_review" } }]
				}
			}
		) {
			subscription_charges {
				invoice_pdf
				status
				count
				created_at {
					month
				}
			}
		}
	}
`;

export const BRAND_CHECKOUT_LIST = gql`
	query GetBrandCheckoutList(
		$brandId: String
		$state: String
		$orderBy: RootOrderByInput
		$limit: Int
		$offset: Int
		$dateRange: [String]
		$timezone: String
	) {
		cube(
			timezone: $timezone
			limit: $limit
			offset: $offset
			where: {
				brands: { id: { equals: $brandId } }
				checkouts: { state: { equals: $state }, updated_at: { inDateRange: $dateRange } }
			}
			orderBy: $orderBy
		) {
			brands {
				id
				name
				domain
			}
			checkouts {
				state
				skip_reason
				external_id
				total_price
				created_at {
					value
				}
				updated_at {
					value
				}
				# abandoned_at {
				# 	value
				# }
			}
			contacts {
				full_name
			}
		}
		meta: cube(
			timezone: $timezone
			where: {
				brands: { id: { equals: $brandId } }
				checkouts: { state: { equals: $state }, updated_at: { inDateRange: $dateRange } }
			}
		) {
			checkouts {
				count
			}
		}
	}
`;
