import { v4 as uuid } from 'uuid';
import { z } from 'zod';

import type { GraphQlClientType } from '@voyage-lab/cube';
import { GraphqlQueryTypes } from '@voyage-lab/cube';
import type { DatabaseEntity, PostgrestClientType } from '@voyage-lab/db';
import { Schema } from '@voyage-lab/schema';
import type { PartialExcept } from '@voyage-lab/util';

import { ConversationQuery } from '..';

const NumericString = z.string().refine(
	(value) => {
		return !isNaN(parseFloat(value));
	},
	{
		message: 'Value must be a numeric string',
	}
);

export class ConversationData {
	#dbClient: PostgrestClientType;
	#graphqlClient: GraphQlClientType | null;

	constructor(dbClient: PostgrestClientType, graphqlClient: GraphQlClientType | null) {
		this.#dbClient = dbClient;
		this.#graphqlClient = graphqlClient;
	}

	async getSingle(props?: { conversationId?: string; next?: boolean }) {
		//Postgrest does not support sum() on referenced table, querying all orders for customer and calculating sum.
		const query = this.#dbClient.from('conversations').select(`
			id,
			created_at,
			conversation_messages(*),
			workflow_goal_state_change_events(*),
			contact_channels(*,
			  	contacts(*)
			),
			brands!inner(name)
		  `);

		if (props?.conversationId) {
			query.eq('id', props.conversationId);
		}

		const { data, error } = await query.order('created_at', { ascending: false }).limit(1).maybeSingle();

		let next: string | null = '';
		let prev: string | null = '';
		const products = [];
		//Get Next & Prev Record Id
		if (props?.next) {
			const nextQuery = this.#dbClient.from('conversations').select(`id`).lt('created_at', data?.created_at);

			const prevQuery = this.#dbClient.from('conversations').select(`id`).gt('created_at', data?.created_at);

			const [nextResult, prevResult] = await Promise.all([
				nextQuery.order('created_at', { ascending: false }).limit(1).maybeSingle(),
				prevQuery.order('created_at', { ascending: true }).limit(1).maybeSingle(),
			]);
			next = nextResult.data?.id !== undefined ? nextResult.data.id : null;
			prev = prevResult.data?.id !== undefined ? prevResult.data.id : null;
		}

		return { ...data, prev, next };
	}

	async getCheckoutDetails(props?: { conversationId?: string }) {
		const query = this.#dbClient.from('conversations').select(`
			contact_channels(*,
				contacts(
					created_at, 
					orders(
						total
					)
				)
			),
			checkouts!inner(*),
			brands!inner(name),
			workflows(
				id,
				name,
				type,
				rules,
				action
			)
		`);

		if (props?.conversationId) {
			query.eq('id', props.conversationId);
		}

		const { data, error } = await query.order('created_at', { ascending: false }).limit(1).maybeSingle();

		const products =
			data?.checkouts.cleaned_raw_data?.detail?.payload?.line_items?.map((p) => {
				return {
					price: p?.price,
					title: p?.title,
					quantity: p?.quantity,
					total: p?.line_price,
					variant: p?.variant_title,
				};
			}) || [];

		return { ...data, products };
	}

	async getAll(props?: {
		brandId: string;
		checkoutState?: string[];
		workflow?: string;
		q?: string;
		from?: string;
		to?: string;
		limit?: number;
		offset?: number;
	}) {
		const query = this.#dbClient.from('conversations').select(`
                id,
                created_at,
                updated_at,
                contact_channels!inner(username,
                    contacts(family_name, given_name)
                ),
				checkouts!inner(state),
				conversation_messages(count)
            `);

		if (props?.brandId) {
			query.eq('brand_id', props.brandId);
		}

		if (props?.checkoutState) {
			query.in('checkouts.state', props.checkoutState);
		}

		if (props?.workflow && props?.workflow !== undefined) {
			query
				.not('workflows', 'is', null) //filter null contacts
				.eq('workflows.type', props?.workflow);
		}

		if (props?.q && props?.q !== undefined) {
			//filter null contacts
			query.not('contact_channels', 'is', null);

			//Filter Number
			const isNumberLike = NumericString.safeParse(props?.q).success;

			const searchStr = props?.q;
			const [firstName, lastName] = searchStr.split(' ');

			if (isNumberLike) {
				query.ilike(`contact_channels.username`, `%${props?.q}%`);
			} else {
				query.or(
					`given_name.ilike.%${props?.q}%, family_name.ilike.%${props?.q}%, and(given_name.ilike.%${firstName}%, family_name.ilike.%${lastName}%)`,
					{
						referencedTable: 'contact_channels.contacts',
					}
				);
			}
		}

		if (props?.from && props?.to) {
			query.gte('created_at', props?.from).lte('created_at', props?.to);
		}

		// //Limit messages
		// query
		// 	.order('created_at', { referencedTable: 'conversation_messages', ascending: false })
		// 	.limit(1, { referencedTable: 'conversation_messages' });

		if (props?.offset) {
			const limit = props.limit || 100;
			query.range(props.offset, props.offset + limit - 1);
		}

		return query.order('created_at', { ascending: false });
	}

	async getConversationCount() {
		if (!this.#graphqlClient) throw new Error('GraphQL client not initialized');

		const { data, errors } = await this.#graphqlClient({
			operation: GraphqlQueryTypes.GetConversationStatsDocument,
			variables: {},
		});

		return data;
	}

	async getAllConvo(brandId: string) {
		if (!this.#graphqlClient) throw new Error('GraphQL client not initialized');

		const { data, errors } = await this.#graphqlClient({
			operation: ConversationQuery.ALL_CONVERSATIONS,
			variables: { brandId },
		});
		return data;
	}

	async getSingleConvo(id: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');

		const { data, error } = await this.#dbClient
			.from('conversations')
			.select(
				`
				id,
                created_at,
                updated_at,
                contact_channels!inner(username,
                    contacts(family_name, given_name)
                ),
				brands!inner(name),
				workflow_goal_state_change_events (
					state
				),
                conversation_messages()
			`
			)
			.eq('id', id)
			.order('created_at', { referencedTable: 'workflow_goal_state_change_events', ascending: false })
			.limit(1)
			.single();

		if (error) {
			console.error('Error fetching single conversation:', error);
			throw error;
		}

		return data;
	}

	async getSingleConvoThread(id: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');

		const { data, error } = await this.#dbClient
			.from('conversations')
			.select(
				`conversation_messages (
					id,
					body,
					direction,
					created_at
				),
				conversation_feedback (
					id,
					message_id
				),
				workflow_goal_state_change_events (
					id,
					state,
					created_at
				)
			`
			)
			.eq('id', id)
			.order('created_at', { foreignTable: 'conversation_messages' })
			.order('created_at', { foreignTable: 'workflow_goal_state_change_events' })
			.limit(1)
			.maybeSingle();

		if (error) {
			console.error('Error fetching single conversation thread:', error);
			throw error;
		}

		return data;
	}

	async getSingleConvoPrevAndNext(date: { value: string }, id: string) {
		if (!this.#graphqlClient) throw new Error('GraphQL client not initialized');

		const { data, errors } = await this.#graphqlClient({
			operation: ConversationQuery.NEXT_AND_PREV_CONVERSATION,
			variables: { datetime: date.value, id },
		});

		return data;
	}

	async getSingleConvoByFrontID(frontId: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversations')
			.select('id, brand_id')
			.eq('extra_data->>front_id', frontId)
			.limit(1)
			.maybeSingle();
		return data;
	}

	async getEscalation(id: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversation_escalations')
			.select('brand_id, question, processed_question, processed_response')
			.eq('id', id)
			.maybeSingle();
		return data;
	}

	async listEscalationsByBrand(brandId: string, limit: number) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversation_escalations')
			.select('id, brand_id, question, processed_response, is_added_kb, created_at')
			.eq('brand_id', brandId)
			.order('created_at', { ascending: false })
			.limit(limit);
		return data;
	}

	async getConversationFeedbacks(props: { page: number; limit?: number; brandId?: string; search?: string }) {
		const limit = props.limit || 10;
		const offset = (props.page - 1) * limit;

		const query = this.#dbClient.from('conversation_feedback').select(
			`*,
				messages:conversation_messages(body),
				conversations!inner(
					id,
					brand_id,
					contact_channels!inner(
						contacts(given_name, family_name),
						username
					)
				)
			`,
			{ count: 'exact' }
		);

		if (props.brandId) query.eq('conversations.brand_id', props.brandId);
		if (props.search) {
			const phrases = props.search
				.trim()
				.toLowerCase()
				.split(/\s+/)
				.map((phrase) => `'${phrase}'`)
				.join(' | ');
			query.textSearch('comment', phrases);
		}

		return query.range(offset, offset + limit - 1).order('created_at', { ascending: false });
	}

	async getFeedback(props: { messageId?: string; id?: string }) {
		// Validation
		if (!props.messageId && !props.id) throw new Error('messageId or id is required');

		const query = this.#dbClient.from('conversation_feedback').select(`*,messages:conversation_messages(body)`);

		// Filters
		if (props.messageId) query.eq('message_id', props.messageId);
		if (props.id) query.eq('id', props.id);

		return await query.limit(1).maybeSingle();
	}

	async updateEscalationAddedKB(escalationId: string) {
		if (!this.#dbClient) throw new Error('dbClient not initialized');
		const { data, error } = await this.#dbClient
			.from('conversation_escalations')
			.update({
				is_added_kb: true,
			})
			.eq('id', escalationId);
		return data;
	}

	async upsertFeedback(props: { data: Partial<DatabaseEntity['conversation_feedback']> }) {
		// Assign Defaults
		// @ts-expect-error: Zod vs Postgrest type missmatch
		if (!props.data.created_at) props.data.created_at = new Date();
		if (!props.data.id) props.data.id = uuid();

		// Validations
		const validData = Schema.conversationFeedbackInputSchema
			.pick({
				id: true,
				created_at: true,
				rating: true,
				comment: true,
				message_id: true,
				conversation_id: true,
			})
			.parse(props.data);

		return this.#dbClient
			.from('conversation_feedback')
			.upsert(
				{
					id: validData.id,
					conversation_id: validData.conversation_id,
					message_id: validData.message_id,
					created_at: validData.created_at.toISOString(),
					updated_at: new Date().toISOString(),
					rating: validData.rating,
					comment: validData.comment,
				},
				{ onConflict: 'message_id' }
			)
			.select('*')
			.maybeSingle();
	}

	// async getCheckoutInfo(id: string) {
	// 	if (!this.#graphqlClient) throw new Error('GraphQL client not initialized');

	// 	const { data, errors } = await this.#graphqlClient({
	// 		operation: ConversationQuery.CHECKOUT_INFO,
	// 		variables: { id },
	// 	});

	// 	return data;
	// }

	async initiate({
		data: { conversation, message },
	}: {
		data: {
			conversation: PartialExcept<DatabaseEntity['conversations'], 'brand_id' | 'channel_id'>;
			message: PartialExcept<DatabaseEntity['conversation_messages'], 'body'>;
		};
	}) {
		// Assign Defaults
		conversation = Object.assign(conversation, {
			...conversation,
			id: conversation.id || uuid(),
			created_at: conversation.created_at || new Date().toISOString(),
			updated_at: conversation.updated_at || new Date().toISOString(),
			is_hidden: conversation.is_hidden ?? true,
		} satisfies Partial<DatabaseEntity<'insert'>['conversations']>);

		message = Object.assign(message, {
			...message,
			id: message.id || uuid(),
			conversation_id: message.conversation_id || conversation.id,
			created_at: message.created_at || new Date().toISOString(),
			updated_at: message.updated_at || new Date().toISOString(),
			status: message.status || 'auto_queued',
			direction: message.direction || 'outgoing',
		} satisfies Partial<DatabaseEntity<'insert'>['conversation_messages']>);

		// Validations
		const validConversation = Schema.conversationsInputSchema.parse(conversation);
		const validMessage = Schema.conversationMessagesInputSchema.parse(message);

		const conversationCreateRes = await this.#dbClient
			.from('conversations')
			.insert(validConversation as unknown as DatabaseEntity['conversations'])
			.select('*')
			.single();

		if (!conversationCreateRes.data) throw new Error('Failed to create conversation');
		validMessage.conversation_id = conversationCreateRes.data.id;

		const messageCreateRes = await this.#dbClient
			.from('conversation_messages')
			.insert(validMessage as unknown as DatabaseEntity['conversation_messages'])
			.select('*')
			.single();

		if (!messageCreateRes.data) {
			await this.#dbClient.from('conversations').delete().eq('id', conversationCreateRes.data.id);
			throw new Error('Failed to create message');
		}

		return {
			conversation: conversationCreateRes.data,
			message: messageCreateRes.data,
		};
	}
}
