import { AccountData } from '@voyage-lab/core-account';
import { AuthData } from '@voyage-lab/core-auth';
import { BillingData } from '@voyage-lab/core-billing';
import { BrandData } from '@voyage-lab/core-brand';
import { ConversationData } from '@voyage-lab/core-conversation';
import type { EmailService } from '@voyage-lab/core-email';
import { IntegrationCoreData } from '@voyage-lab/core-integration';
import { KnowledgeBaseData } from '@voyage-lab/core-kb';
import { WorkflowData } from '@voyage-lab/core-workflow';
import type { GraphQlClientType } from '@voyage-lab/cube';
import { type PostgrestClientType } from '@voyage-lab/db';

import type { AuthSession } from '../auth';

export class DataClient {
	dbClient: PostgrestClientType;
	graphqlClient: GraphQlClientType | null;
	session: AuthSession | null;
	account: AccountData;
	auth: AuthData;
	workflow: WorkflowData;
	integration: IntegrationCoreData;
	conversation: ConversationData;
	brands: BrandData;
	kb: KnowledgeBaseData;
	billing: BillingData;
	email?: EmailService;

	/**
	 * Initializes a new instance of the KnowledgeBaseData class.
	 * @param dbClient - The database client object.
	 */
	constructor({
		dbClient,
		graphqlClient,
		email,
		session,
		jwtSecret,
	}: {
		dbClient: PostgrestClientType;
		graphqlClient: GraphQlClientType | null;
		email?: EmailService;
		session: AuthSession | null;
		jwtSecret?: string;
	}) {
		this.dbClient = dbClient;
		this.graphqlClient = graphqlClient;
		this.conversation = new ConversationData(this.dbClient, this.graphqlClient);
		this.brands = new BrandData(this.dbClient);
		this.session = session;
		this.account = new AccountData({ dbClient: this.dbClient, jwtSecret: jwtSecret!, email });
		this.auth = new AuthData({ dbClient: this.dbClient, jwtSecret: jwtSecret!, email });
		this.workflow = new WorkflowData(this.dbClient);
		this.integration = new IntegrationCoreData(this.dbClient);
		this.kb = new KnowledgeBaseData(this.dbClient);
		this.billing = new BillingData({
			dbClient: this.dbClient,
			shopifyApiKey: process.env.INTEGRATIONS_SHOPIFY_CLIENT_SECRET!,
			stripeApiKey: process.env.STRIPE_SECRET_KEY!,
			email: email!,
		});

		// this.integrationShopify = new IntegrationShopifyData({
		// 	dbClient: this.#dbClient,
		// 	shopifyWebhookAddress: process.env.INTEGRATIONS_SHOPIFY_WEBHOOK_ADDRESS,
		// });
	}
}
