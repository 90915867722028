import type { AccountData } from '@voyage-lab/core-account';
import type { AuthData } from '@voyage-lab/core-auth';
import type { ContactData } from '@voyage-lab/core-messaging';
import type { DatabaseEntity, PostgrestClientType } from '@voyage-lab/db';
import type { PartialExcept } from '@voyage-lab/util';

import type { IntegrationCoreData } from '../data';
import type { KlaviyoProfileResponse } from '../types/klaviyo.types';

/**
 * The IntegrationProvider is the base class for all integration providers.
 * It is used to authenticate the user and load the user's account.
 */
export abstract class IntegrationProvider implements ConstructorArgs {
	/** The id of the integration. */
	static id: DatabaseEntity['integrations']['id'];
	static type: string;

	// Internal data clients
	integrationData: IntegrationCoreData;
	accountData: AccountData;
	authData: AuthData;
	contactData: ContactData;
	dataClient: PostgrestClientType;

	/** The credentials of the integration. */
	credentials: ConstructorArgs['credentials'];

	constructor(arg: ConstructorArgs) {
		this.integrationData = arg.integrationData;
		this.accountData = arg.accountData;
		this.authData = arg.authData;
		this.dataClient = arg.dataClient;
		this.credentials = arg.credentials;
		this.contactData = arg.contactData;
	}

	// ----------------------------- Base methods ----------------------------- //
	/** The auth method is used to authenticate the user. It is called when the user is redirected back to the app. */
	abstract auth(args: CommonArgs): Promise<CommonReturn>;

	/** The open method is used to open the integration. */
	abstract open(args: CommonArgs): Promise<CommonReturn>;

	/** The uninstall method is used to uninstall the integration. */
	abstract uninstall(): Promise<void>;

	// ----------------------------- Other optional operations ----------------------------- //
	/** Handle webhook events from the integration. */
	abstract handleEvent?(args: EventArgs): Promise<void>;

	// ----------------------------- Resource operations ----------------------------- //
	/** Get an authenticated and typed fetch client for the integration. */
	abstract resource(args: ResourceArgs): ResourceReturn;

	abstract identify(args: IdentifyArgs): Promise<IdentifyReturn>;
}

export type ConstructorArgs = {
	integrationData: IntegrationCoreData;
	accountData: AccountData;
	authData: AuthData;
	dataClient: PostgrestClientType;
	contactData: ContactData;
	credentials: {
		clientId: string;
		clientSecret: string;
		authCallbackUrl?: string;
	};
};

export type CommonArgs = {
	params?: Record<string, string> | unknown;
	rawRequest?: Partial<Request>;
	rawResponse?: Partial<Response>;
	data?: {
		brand?: {
			integrationId: DatabaseEntity['integrations']['id'];
			lookupId: string;
			shopifyDomain?: string;
			bid?: string;
		};
		user?: DatabaseEntity['users'];
		lead?: DatabaseEntity['leads'];
	} & unknown;
};

type CommonReturn = {
	redirect?: string;
	rawResponse?: Partial<Response>;
	rawRequest?: Partial<Request>;
	data?: unknown | null;
};

export type ResourceArgs = {
	integration: DatabaseEntity['brand_integrations'];
};

export type ResourceReturn = {
	fetch: <TResourcePath extends keyof ResourcePathToResource>(
		input: TResourcePath,
		init?: RequestInit & {
			data?: Partial<ResourcePathToResource[TResourcePath]>;
		}
	) => Promise<Response & { data?: ResourcePathToResource[TResourcePath] }>;
};

type ResourcePathToResource = {
	'/discounts': DatabaseEntity['discount_rules'];
	'/products': DatabaseEntity['products'];
	'/profiles': KlaviyoProfileResponse;
};

export type IdentifyArgs = {
	integration: DatabaseEntity['brand_integrations'];
	identity?: {
		email?: string;
		phone?: string;
		id?: string;
		cart_id?: string;
	};
	data?: Parameters<ContactData['initiate']>[0] & unknown;
};

export type EventArgs = {
	integration: DatabaseEntity['brand_integrations'];
	event: unknown;
};

export type IdentifyReturn = {
	contact: DatabaseEntity['contacts'] | null;
	channels: DatabaseEntity['contact_channels'][] | null;
};
