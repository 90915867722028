import type { DateRange, DeeplyReadonly, ResultSet, TimeDimensionGranularity } from '@cubejs-client/core';
import type { SortingState } from '@tanstack/react-table';
import { tz } from 'moment-timezone';

import { Helpers } from '@voyage-lab/util';

import type { GraphqlQueryTypes } from '..';
import { CubeConstant } from '..';

const dottedStringToObject = (str: string, value: string) => {
	const keys = str.split('.');
	return keys.reduceRight((acc, curr) => {
		return {
			[curr]: acc,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	}, value as any);
};

export const parseCubeSorting = (sorting: SortingState) => {
	return sorting?.reduce((acc, { id, desc }) => {
		return dottedStringToObject(id, desc ? 'desc' : 'asc');
	}, {});
};

export const generateGranularity = (range: [string, string]): TimeDimensionGranularity => {
	const from = new Date(range[0]);
	const to = new Date(range[1]);

	const diffDays = Helpers.Time.differenceInDays(to, from);
	if (diffDays <= 5) {
		// less than 5 days
		return 'hour';
	}

	if (diffDays <= 2 * 30) {
		// less than 2 months
		return 'day';
	}

	if (diffDays <= 4 * 30) {
		// less than 4 months
		return 'week';
	}

	if (diffDays <= 5 * 365) {
		// less than 5 years
		return 'month';
	}

	return 'year';
};

export const generateGranularityObject = (range: [string, string] | string) => {
	const granularity = typeof range === 'string' ? range : generateGranularity(range);
	return {
		isHourly: granularity === 'hour',
		isDaily: granularity === 'day',
		isWeekly: granularity === 'week',
		isMonthly: granularity === 'month',
		isYearly: granularity === 'year',
	};
};

export const adjustTimezone = (date: Date | string) => {
	const newDate = tz(date, CubeConstant.DEFAULT_TIMEZONE).utc().format('YYYY-MM-DDTHH:mm:SS.SSS');
	return new Date(newDate + 'Z');
};

export const formatCubeDate = (date: Date | string) => {
	try {
		const dateWithCurrentTime = Helpers.Time.addCurrentTime(new Date(date));
		return tz(dateWithCurrentTime, CubeConstant.DEFAULT_TIMEZONE).format('YYYY-MM-DD');
	} catch (e) {
		if (typeof date === 'string') {
			return date;
		} else {
			return date.toISOString();
		}
	}
};

export type DateRangeType = 'daily' | 'weekly' | 'monthly' | 'yearly' | '';
export type DateRangeObj = {
	currRange: string[];
	prevRange: string[];
};
type RangeType = {
	from: string | Date;
	to: string | Date;
};

export const generateCubeDateRange = (range: RangeType) => {
	const dFrom = new Date(range.from);
	const dTo = new Date(range.to);
	const diff = Helpers.Time.differenceInDays(dTo, dFrom);
	const compareFrom = Helpers.Time.addDays(dFrom, -(diff + 1));
	const compareTo = Helpers.Time.addDays(dTo, -(diff + 1));

	let currRange;
	let prevRange;

	if (range.from === range.to && Helpers.Time.isToday(dFrom)) {
		prevRange = [formatCubeDate(compareFrom), formatCubeDate(compareFrom)];
		currRange = [formatCubeDate(range.from), formatCubeDate(range.to)];
	} else if (range.from === range.to && Helpers.Time.isYesterday(dFrom)) {
		prevRange = [formatCubeDate(compareFrom), formatCubeDate(compareFrom)];
		currRange = [formatCubeDate(range.from), formatCubeDate(range.to)];
	} else if (
		Helpers.Time.isStartOfAMonth(dFrom) &&
		Helpers.Time.isEndOfAMonth(dTo) &&
		Helpers.Time.isSameMonth(dFrom, dTo)
	) {
		const prevMonth = Helpers.Time.addMonths(dFrom, -1);
		const start = Helpers.Time.startOfMonth(prevMonth);
		const end = Helpers.Time.endOfMonth(prevMonth);
		prevRange = [formatCubeDate(start), formatCubeDate(end)];
		currRange = [formatCubeDate(range.from), formatCubeDate(range.to)];
	} else if (
		Helpers.Time.isStartOfYear(dFrom) &&
		Helpers.Time.isEndOfAYear(dTo) &&
		Helpers.Time.isSameYear(dFrom, dTo)
	) {
		const prevYear = Helpers.Time.addYears(dFrom, -1);
		const start = Helpers.Time.startOfYear(prevYear);
		const end = Helpers.Time.endOfYear(prevYear);
		prevRange = [formatCubeDate(start), formatCubeDate(end)];
		currRange = [formatCubeDate(range.from), formatCubeDate(range.to)];
	} else {
		prevRange = [formatCubeDate(compareFrom), formatCubeDate(compareTo)];
		currRange = [formatCubeDate(range.from), formatCubeDate(range.to)];
	}

	return {
		currRange,
		prevRange,
	};
};

export const filterData = (result: ResultSet | undefined, dateRange: DeeplyReadonly<DateRange | undefined>) => {
	{
		/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dimension = (result as any)?.loadResponse.pivotQuery.timeDimensions[0];
	const dateField = `${dimension?.dimension}.${dimension?.granularity}`;
	return result?.tablePivot()?.filter((d) => {
		const from = new Date(dateRange?.[0] as string);
		const to = new Date(dateRange?.[1] as string);
		const date = new Date(d[dateField] as string);
		return new Date(date) >= new Date(from) && new Date(date) <= new Date(to);
	});
};

export const getCubeDataCount = (
	result: ResultSet | undefined,
	field: string,
	dateRange: DeeplyReadonly<DateRange | undefined>
) => {
	const data = filterData(result, dateRange);
	return data?.reduce((acc, row) => acc + +row[field], 0) || 0;
};

export const getCubeDataList = (
	result: ResultSet | undefined,
	field: string,
	dateRange: DeeplyReadonly<DateRange | undefined>
) => {
	const data = filterData(result, dateRange);
	return data?.map((row) => +row[field]) || [];
};

export const formatOnGranularity = (dateObj: Partial<GraphqlQueryTypes.TimeDimension> | undefined | null) => {
	try {
		if (!dateObj) return '';
		const arrays = Object.entries(dateObj);
		const [key, value] = arrays[0] as [TimeDimensionGranularity, string];
		if (!key || !value) return '';
		const date = new Date(value);

		if (key === 'hour') {
			return Helpers.Time.format(date, 'd MMM h:mm a');
		} else if (key === 'day') {
			return Helpers.Time.format(date, 'd MMM');
		} else if (key === 'month') {
			return Helpers.Time.format(date, 'MMM yyyy');
		} else if (key === 'year') {
			return Helpers.Time.format(date, 'yyyy');
		} else {
			return Helpers.Time.format(date, 'MMM d, yyyy');
		}
	} catch (error) {
		return '';
	}
};

export const parseTimeDimension = (date?: Partial<GraphqlQueryTypes.TimeDimension> | undefined | null) => {
	try {
		const arrays = Object.entries(date || {});
		const [key, value] = arrays[0] as [TimeDimensionGranularity, string];
		return value;
	} catch (error) {
		return null;
	}
};

export const timeDimensionToDate = (date?: Partial<GraphqlQueryTypes.TimeDimension> | undefined | null) => {
	const d = parseTimeDimension(date);
	return d ? new Date(d) : null;
};
