import * as jwt from 'jsonwebtoken';
import { v4 as uuid } from 'uuid';

import type { TypesT } from '@voyage-lab/db';
import { Constants } from '@voyage-lab/db';

import { BaseIntegrationProvider } from '../../base';
import type {
	CommonArgs,
	ConstructorArgs,
	EventArgs,
	IdentifyArgs,
	IdentifyReturn,
	ResourceArgs,
	ResourceReturn,
} from '../../provider';

export class BigCommerce extends BaseIntegrationProvider {
	static override id = Constants.Integration.BigCommerceIntegrationId;
	static override type = 'bigcommerce' as const;

	API_URL = 'https://api.bigcommerce.com';
	LOGIN_URL = 'https://login.bigcommerce.com';
	API_VERSION = 'v2';

	constructor(arg: ConstructorArgs) {
		super(arg);
	}

	override async auth({ params }: { params: AuthParams }) {
		const request: RequestInit = {
			method: 'POST',
			headers: {
				'User-Agent': 'vyg.ai/1.0',
				'Content-Type': 'application/json',
				'Accept-Encoding': 'gzip, deflate',
			},
			body: JSON.stringify({
				client_id: this.credentials.clientId,
				client_secret: this.credentials.clientSecret,
				redirect_uri: this.credentials.authCallbackUrl,
				grant_type: 'authorization_code',
				code: params.code,
				scope: params.scope,
				context: params.context,
			}),
		};

		const response = await fetch(`${this.LOGIN_URL}/oauth2/token`, request);
		const session: LoginEntity = await response.json();

		console.info({
			session,
		});

		if (!session.context) throw new Error('No store hash found');
		if (!session.access_token) throw new Error('No access token found');

		const store = await fetch(`${this.API_URL}/${session.context}/${this.API_VERSION}/store`, {
			headers: {
				'User-Agent': 'vyg.ai/1.0',
				'X-Auth-Token': session.access_token,
				Accept: 'application/json',
			},
		});

		const storeData = (await store.json()) as Store;
		console.info({ storeData });

		return await super.auth({
			data: {
				brand: {
					integrationId: BigCommerce.id,
					lookupId: session.context,
				},
				lead: {
					email: session.owner?.email || session.user.email,
					first_name: storeData.first_name,
					last_name: storeData.last_name,
					// phone: storeData.phone,
					id: session.store_hash || '',
					annual_revenue: storeData.industry?.toLowerCase() === 'retail' ? 1000000 : 500000,
					source_name: 'bigcommerce_store',
					source_type: BigCommerce.type,
					tenant_id: null,
					updated_at: new Date().toISOString(),
					user_id: null,
					store_type: BigCommerce.type,
					store_url: storeData.domain,
					support_email: storeData.admin_email,
					support_phone: storeData.phone,
					company_name: storeData.name,
					company_website: storeData.domain,
					billing_email: storeData.admin_email,
					brand_id: storeData.logo.url,
					created_at: new Date().toISOString(),
					extra_data: {
						installation_source: 'bigcommerce',
						bigcommerce: {
							store: storeData,
							session: session,
						},
					},
				},
			},
		});
	}

	override async open(props: CommonArgs & { params: LoadParams }) {
		// Validation
		const jwtData = jwt.verify(props.params.signed_payload_jwt, this.credentials.clientSecret) as LoadJwtPayload;

		return await super.open({
			data: {
				brand: {
					lookupId: jwtData.sub,
					integrationId: BigCommerce.id,
				},
				user: {
					email: jwtData.user.email,
					family_name: jwtData.user.username || '',
					given_name: '',
					tenant_id: null!, // Should be filled by  base provider
					id: uuid(),
					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),
					extra_data: {},
					hubspot_id: '',
					is_enabled: true,
					is_staff: false,
					last_login: new Date().toISOString(),
					password: null,
					auth: {
						provider: BigCommerce.type as TypesT.AuthProvider,
						bigcommerce: {
							user_id: jwtData.user.id.toString(),
						},
					},
					phone: '',
					role: 'member',
					state: 'active',
				},
			},
		});
	}

	override async handleEvent(props: EventArgs) {
		console.log({ props });
	}

	override resource(args: ResourceArgs): ResourceReturn {
		if (args.integration.integration_id !== BigCommerce.id) {
			throw new Error(
				`Invalid integration id, got ${args.integration.integration_id} expected ${BigCommerce.id}`
			);
		}

		return {
			fetch: this.createFetch({
				url: `${this.API_URL}/${args.integration.settings.credentials.store_hash}/${this.API_VERSION}`,
				headers: {
					'X-Auth-Token': args.integration.settings.credentials.access_token,
				},
			}),
		};
	}

	override async identify(args: IdentifyArgs): Promise<IdentifyReturn> {
		throw new Error('Method not implemented.');
	}
}

/** Represents a user entity. */
interface User {
	/** The user's email address. */
	email: string;
	/** The user's unique identifier. */
	id: number;
	/** The user's username, optional. */
	username?: string;
}

/** Represents a login entity containing authentication details. */
interface LoginEntity {
	/** The access token for authentication, optional. */
	access_token?: string;
	/** The context of the login, typically the store hash. */
	context: string;
	/** The owner of the login, optional. */
	owner?: User;
	/** The scope of the authentication, optional. */
	scope?: string;
	/** The hash of the store, optional. */
	store_hash?: string;
	/** The subject of the login, typically the user ID. */
	sub?: string;
	/** The timestamp of the login. */
	timestamp?: number;
	/** The user associated with the login. */
	user: User;
}

/** Represents the payload of a loaded JWT for BigCommerce integration. */
type LoadJwtPayload = {
	/** The audience for whom the JWT is intended. */
	aud: string;
	/** The issuer of the JWT. */
	iss: string;
	/** The time at which the JWT was issued, in seconds since epoch. */
	iat: number;
	/** The time before which the JWT must not be accepted for processing, in seconds since epoch. */
	nbf: number;
	/** The expiration time on or after which the JWT must not be accepted for processing, in seconds since epoch. */
	exp: number;
	/** A unique identifier for the JWT. */
	jti: string;
	/** The subject of the JWT, typically the user ID. */
	sub: string;
	/** The user associated with the JWT. */
	user: User;
	/** The owner associated with the JWT. */
	owner: User;
	/** The URL associated with the JWT. */
	url: string;
	/** The channel ID associated with the JWT. Can be null. */
	channel_id: string | null;
};

/**
 * The auth params are the parameters that are used to authenticate the user and get the access token
 * @url https://developer.bigcommerce.com/docs/integrations/apps/guide/auth#receiving-the-auth-callback
 */
type AuthParams = {
	/* The proverbial code in the code grant authorization flow; exchange for a semi-permanent access_token. */
	code: string;
	/* A space-separated list of the OAuth scopes associated with this app's API account. */
	scope: string;
	/* The path that identifies the store in API requests to https://api.bigcommerce.com; a string of the form stores/{STORE_HASH}. */
	context: string;
	/* The ID of the Developer Portal account that registered the app profile. */
	account_uuid: string;
};

/**
 * The load params are the parameters that are used to load the user's account.
 * They are returned by the BigCommerce API when the user is redirected back to the app.
 */
type LoadParams = {
	/** The legacy signed payload */
	signed_payload: string;
	/** The JWT signed payload */
	signed_payload_jwt: string;
};

/**
 * @url https://developer.bigcommerce.com/docs/rest-management/store-information#get-store-information
 */
type Store = {
	/** The store hash, a unique store identifier. */
	id: string;
	/** The UUID of the account to which the store belongs. */
	account_uuid: string;
	/** Primary domain name. */
	domain: string;
	/** Store’s current HTTPS URL. */
	secure_url: string;
	/** The secure hostname of the control panel. */
	control_panel_base_url: string;
	/** The status of the store. */
	status: string;
	/** Store’s name. */
	name: string;
	/** Primary contact’s first name (as defined during the store sign-up process). */
	first_name: string;
	/** Primary contact’s last name (as defined during the store sign-up process). */
	last_name: string;
	/** Display address. */
	address: string;
	/** Country where the store is located (as defined during the store sign-up process). */
	country: string;
	/** Two-letter ISO 3166-1 country code. */
	country_code: string;
	/** Display phone number. */
	phone: string;
	/** Email address of the store administrator/owner. */
	admin_email: string;
	/** Email address for orders and fulfillment. */
	order_email: string;
	/** The URL of the favicon image associated with the website. */
	favicon_url: string;
	/** Timezone information. */
	timezone: {
		/** A string identifying the time zone, in the format Continent/City. */
		name: string;
		/** A negative or positive number, identifying the offset from UTC/GMT, in seconds, during winter/standard time. */
		raw_offset: number;
		/** A negative or positive number, identifying the offset from UTC/GMT, in seconds, during summer/daylight saving time. */
		dst_offset: number;
		/** A boolean indicating whether this time zone observes daylight saving time. */
		dst_correction: boolean;
		/** Date format information. */
		date_format: {
			/** A string that defines dates’ display format, in the pattern M jS Y. */
			display: string;
			/** A string that defines the CSV export format for orders, customers, and products, in the pattern M jS Y. */
			export: string;
			/** A string that defines dates’ extended-display format, in the pattern M jS Y @ g:i A. */
			extended_display: string;
		};
	};
	/** Default language code. */
	language: string;
	/** Default currency code. */
	currency: string;
	/** Default symbol for values in the currency. */
	currency_symbol: string;
	/** Default decimal separator for values in the currency. */
	decimal_separator: string;
	/** Default thousands separator for values in the currency. */
	thousands_separator: string;
	/** Default decimal places for values in the currency. */
	decimal_places: number;
	/** Default position of the currency symbol (left or right). */
	currency_symbol_location: string;
	/** Default weight units (metric or imperial). */
	weight_units: string;
	/** Default dimension units (metric or imperial). */
	dimension_units: string;
	/** The number of decimal places. */
	dimension_decimal_places: number;
	/** The symbol that separates the whole numbers from the decimal points. */
	dimension_decimal_token: string;
	/** The symbol used to denote thousands. */
	dimension_thousands_token: string;
	/** Name of the BigCommerce plan to which this store is subscribed. */
	plan_name: string;
	/** Level of the BigCommerce plan to which this store is subscribed. */
	plan_level: string;
	/** Whether the payment plan associated with the store is still in the trial phase. */
	plan_is_trial: boolean;
	/** Industry, or vertical category, in which the business operates. */
	industry: string;
	/** Either an object describing the logo image, or an empty array. */
	logo: {
		/** URL of the logo image. */
		url: string;
	};
	/** A Boolean value that indicates whether or not prices are entered with tax. */
	is_price_entered_with_tax: boolean;
	/** The numeric ID of the store. This is a different unique ID than the store hash. */
	store_id: number;
	/** The ID of the default channel. The ID of the first hosted storefront created on the store is 1. */
	default_channel_id: number;
	/** The BigCommerce ID of the website associated with the default storefront. */
	default_site_id: number;
	/** Active comparison modules. */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	active_comparison_modules: any[];
	/** Describes some aspects of the storeʼs tech stack and configuration settings that affect the features available for the store to use. */
	features: {
		/** Indicates whether a store is using a Stencil theme. */
		stencil_enabled: boolean;
		/** Indicates whether there is site-wide https. */
		sitewidehttps_enabled: boolean;
		/** The ID of the Facebook by Meta catalog. */
		facebook_catalog_id: string;
		/** What type of checkout is enabled on the store. */
		checkout_type: string;
		/** Indicates whether wishlists are enabled. */
		wishlists_enabled: boolean;
		/** Describes whether you can use the GraphQL Storefront API on this store. */
		graphql_storefront_api_enabled: boolean;
		/** Indicates whether the store is tracking the values of the cookie and privacy consent settings that the shopper consented to and configured. */
		shopper_consent_tracking_enabled: boolean;
		/** Indicates whether the storeʼs plan provides the possibility of using more than one storefront or sales channel. */
		multi_storefront_enabled: boolean;
		/** Storefront limits information. */
		storefront_limits: {
			/** Describes the number of storefronts active on the store. */
			active: number;
			/** Describes the total number of storefronts associated with the store, including both active and inactive storefronts. */
			total_including_inactive: number;
		};
	};
};
