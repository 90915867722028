import { v4 as uuid } from 'uuid';
import { z } from 'zod';

import { AppError } from '@voyage-lab/core-common';
import type { IntegrationCoreData } from '@voyage-lab/core-integration';
import type { DatabaseEntity, DatabaseEnum } from '@voyage-lab/db';
import { Constants, type PostgrestClientType } from '@voyage-lab/db';
import { Helpers, type PartialExcept } from '@voyage-lab/util';

export class ContactData {
	#dbClient: PostgrestClientType;
	#integrationData: IntegrationCoreData;

	constructor(args: { dbClient: PostgrestClientType; integrationData: IntegrationCoreData }) {
		this.#dbClient = args.dbClient;
		this.#integrationData = args.integrationData;
	}

	/** Initial contact with minimal possible data, this fills required but not provied value with default value */
	async initiate(props: {
		phone?: string;
		email?: string;
		channels?: Partial<DatabaseEntity['contact_channels']>[];
		contact: PartialExcept<DatabaseEntity['contacts'], 'brand_id'>;
	}) {
		// Validation
		if (!props.channels?.length && !props.phone && !props.email) {
			throw new AppError('Either username, phone or email must be provided');
		}

		// Initialization
		let smsIntegration: DatabaseEntity['brand_integrations'] | undefined;
		let emailIntegration: DatabaseEntity['brand_integrations'] | undefined;
		const channelBids = props.channels?.map<string>((c) => c.brand_integration_id!)?.filter(Boolean) || [];

		const missingBids = channelBids.length !== props.channels?.length;
		if (missingBids) {
			// brand_integrations.id was not passed so let's find it or create if it doesn't exist
			const integrationRes = await this.#integrationData.getBi({
				brandId: props.contact.brand_id,
				integrationIds: [...Constants.IntegrationIdsCategory.Sms, ...Constants.IntegrationIdsCategory.Email],
			});

			smsIntegration = integrationRes.data
				?.find((i) => Constants.IntegrationIdsCategory.Sms.some((id) => id === i.id))
				?.brand_integrations?.find((bi) => bi.is_enabled);

			emailIntegration = integrationRes.data
				?.find((i) => Constants.IntegrationIdsCategory.Email.some((id) => id === i.id))
				?.brand_integrations?.find((bi) => bi.is_enabled);

			console.log({ smsIntegration, emailIntegration });

			if (!smsIntegration) {
				const createdIntegrationRes = await this.#integrationData.create({
					data: {
						brand_id: props.contact.brand_id,
						integration_id: Constants.Integration.TwilioIntegrationId,
						status: 'connected',
						created_at: new Date().toISOString(),
						updated_at: new Date().toISOString(),
						id: uuid(),
						settings: {},
						is_enabled: true,
					},
				});
				if (!createdIntegrationRes.data) throw new Error('Failed to create default sms integration');
				smsIntegration = createdIntegrationRes.data;
			}

			if (!emailIntegration) {
				const createdEmailIntegrationRes = await this.#integrationData.create({
					data: {
						brand_id: props.contact.brand_id,
						integration_id: Constants.Integration.EmailIntegrationId,
						status: 'connected',
						created_at: new Date().toISOString(),
						updated_at: new Date().toISOString(),
						id: uuid(),
						settings: {},
						is_enabled: true,
					},
				});
				if (!createdEmailIntegrationRes.data) throw new Error('Failed to create default email integration');
				emailIntegration = createdEmailIntegrationRes.data;
			}
		}

		const channels = props.channels ?? [];
		if (props.phone) channels.push({ username: props.phone });
		if (props.email) channels.push({ username: props.email });

		const usernames = channels.map((c) => c.username).filter(Boolean) as string[];
		const bids = [...channelBids, smsIntegration?.id, emailIntegration?.id].filter(Boolean) as string[];

		const existingContactRes = await this.#dbClient
			.from('contact_channels')
			.select('*,contacts(*)')
			.in('username', usernames)
			.in('brand_integration_id', bids);

		let contact = existingContactRes.data?.find((c) => c.contacts?.id)?.contacts;

		// All channels already exist for this contact
		if (contact?.id && usernames.length === existingContactRes.data?.length) {
			return { contact, channels: existingContactRes.data };
		}

		if (!contact) {
			const contactRes = await this.#dbClient
				.from('contacts')
				.insert({
					...props.contact,
					id: props.contact.id || uuid(),
					brand_id: props.contact.brand_id,
					family_name: props.contact.family_name || '',
					given_name: props.contact.given_name || '',
					external_id: props.contact.external_id || '',
					source: props.contact.source || Constants.Contact.Source,
					created_at: props.contact.created_at || new Date().toISOString(),
					updated_at: props.contact.updated_at || new Date().toISOString(),
				})
				.select()
				.maybeSingle();
			if (!contactRes.data) {
				console.error('Failed to create contact: ' + contactRes.error?.message, props);
				throw new Error('Failed to create contact: ' + contactRes.error?.message);
			}

			contact = contactRes.data;
		}

		if (!contact) throw new Error('Failed to create contact');
		const missingChannels = channels.filter(
			(c) => !existingContactRes.data?.some((ec) => ec.username === c.username)
		);

		// Fill all channels with brand integration id
		const channelsToCreate = missingChannels.map((c) => {
			// Validation
			const isEmail = z.string().email().safeParse(c.username).success;
			if (!isEmail && !smsIntegration?.id) throw new Error('Sms integration not found');
			if (isEmail && !emailIntegration?.id) throw new Error('Email integration not found');

			const defaultChannel: DatabaseEntity<'insert'>['contact_channels'] = {
				id: uuid(),
				brand_integration_id: c.brand_integration_id!,
				username: c.username!,
				status: 'subscribed',
				external_id: c.external_id || '',
				extra_data: c.extra_data! || {},
				contact_id: contact.id,
				created_at: new Date().toISOString(),
				updated_at: new Date().toISOString(),
			};
			if (isEmail && emailIntegration?.id) defaultChannel.brand_integration_id = emailIntegration.id;
			if (smsIntegration?.id && !isEmail) defaultChannel.brand_integration_id = smsIntegration.id;

			const completeChannel = Helpers.Object.deepMerge(defaultChannel, c);
			return completeChannel as DatabaseEntity<'insert'>['contact_channels'];
		});

		const channelRes = await this.#dbClient.from('contact_channels').insert(channelsToCreate).select();
		if (!channelRes.data) throw new Error('Failed to create contact channel: ' + channelRes.error?.message);

		return { contact, channels: channelRes.data };
	}

	async getSingleContact(props: { contactId?: string; brandId?: string; externalId?: string }) {
		if (!props.contactId && (!props.brandId || !props.externalId)) {
			throw new Error('Either contactId or brandId and externalId must be provided');
		}

		const query = this.#dbClient.from('contacts').select('*');
		if (props.contactId) query.eq('id', props.contactId);
		if (props.brandId) query.eq('brand_id', props.brandId);
		if (props.externalId) query.eq('external_id', props.externalId);

		return query.maybeSingle();
	}

	async getSingleChannel(props: { username: string; brandIntegrationId: string }) {
		const query = this.#dbClient
			.from('contact_channels')
			.select('*')
			.eq('username', props.username)
			.eq('brand_integration_id', props.brandIntegrationId);

		return query.maybeSingle();
	}

	async createChannel(props: {
		contactId: string;
		channel: DatabaseEntity['contact_channels'];
		type: 'sms' | 'email';
	}) {
		const integrationRes = await this.#integrationData.getSingleBi({
			brandId: props.contactId,
			integrationIds:
				props.type === 'sms'
					? [...Constants.IntegrationIdsCategory.Sms]
					: [...Constants.IntegrationIdsCategory.Email],
		});
		if (!integrationRes.data?.brand_integrations?.[0]?.id) {
			throw new Error('Email integration not found');
		}
		props.channel.brand_integration_id = integrationRes.data.brand_integrations[0].id;

		// check if channel already exists for that username and brand integration
		const channelRes = await this.getSingleChannel({
			username: props.channel.username,
			brandIntegrationId: props.channel.brand_integration_id,
		});
		if (channelRes.data) {
			return channelRes;
		}

		return await this.#dbClient.from('contact_channels').insert(props.channel).select('*').maybeSingle();
	}
}
