// sort-imports-ignore
import '@shopify/shopify-api/adapters/web-api';
import { createAdminRestApiClient } from '@shopify/admin-api-client';
import type { Session } from '@shopify/shopify-api';
import { LATEST_API_VERSION, shopifyApi, ShopifyHeader } from '@shopify/shopify-api';
import '@shopify/shopify-api/adapters/web-api';
import { Constants } from '@voyage-lab/db';

import { AppError } from '@voyage-lab/core-common';
import { BaseIntegrationProvider } from '../../base';
import type {
	CommonArgs,
	ConstructorArgs,
	IdentifyArgs,
	IdentifyReturn,
	ResourceArgs,
	EventArgs,
} from '../../provider';
export class Shopify extends BaseIntegrationProvider {
	static override id = Constants.Integration.ShopifyIntegrationId;
	static override type = 'shopify' as const;

	static API_VERSION = '2024-10' as const;
	static APP_SCOPES = [
		'read_customers',
		'write_customers',
		'read_orders',
		'write_orders',
		'read_translations',
		'write_translations',
		'read_price_rules',
		'write_price_rules',
		'read_discounts',
		'write_discounts',
		'read_script_tags',
		'write_script_tags',
		'read_products',
		'write_products',
		'read_pixels',
		'write_pixels',
		'read_customer_events',
	];
	shopify: ReturnType<typeof shopifyApi>;
	#callbackUrl: URL;

	constructor(arg: ConstructorArgs) {
		super(arg);
		if (!this.credentials.authCallbackUrl) throw new Error('No auth callback url found');
		const callbackUrl = new URL(this.credentials.authCallbackUrl);
		const hostName = callbackUrl.host;
		const hostScheme = callbackUrl.protocol.replace(':', '') as 'http' | 'https';
		this.#callbackUrl = callbackUrl;

		this.shopify = shopifyApi({
			apiKey: this.credentials.clientId,
			apiSecretKey: this.credentials.clientSecret,
			scopes: Shopify.APP_SCOPES,
			hostName,
			hostScheme,
			apiVersion: LATEST_API_VERSION,
			isEmbeddedApp: false,
			logger: {
				level: 0,
			},
		});
	}

	override async auth({ params, rawRequest, rawResponse }: CommonArgs & { params: AuthParams }) {
		// const { shop, code } = params as AuthParams;
		const isCallback = !!params.code;
		const isRefresh = !!params.bid;

		// Validation
		const sanitizedShop = this.shopify.utils.sanitizeShop(params.shop);
		if (!sanitizedShop) throw new AppError('Invalid shop provided');

		if (!isCallback || isRefresh) {
			// Exchange the authorization code for an access token
			const beginResponse = await this.shopify.auth.begin({
				callbackPath: this.#callbackUrl.pathname,
				isOnline: false,
				shop: sanitizedShop,
				rawRequest,
				rawResponse,
			});

			return {
				// eslint-disable-next-line
				data: {} as any,
				rawResponse: beginResponse,
				rawRequest,
			};
		}

		const callbackResponse = await this.shopify.auth.callback<Session>({
			rawRequest,
		});

		const accessToken = callbackResponse.session.accessToken;
		if (!accessToken) throw new AppError('No access token found');

		const adminApiClient = createAdminRestApiClient({
			storeDomain: params.shop,
			apiVersion: Shopify.API_VERSION,
			accessToken,
		});

		const shopRes = await adminApiClient.get('shop');
		const shop: Shop = (await shopRes.json())?.shop;

		return await super.auth({
			params,
			data: {
				brand: {
					integrationId: Shopify.id,
					lookupId: sanitizedShop,
				},
				lead: {
					id: params.lid ?? '',
					email: shop.email,
					first_name: shop.shop_owner.split(' ')[0],
					last_name: shop.shop_owner.split(' ').slice(1).join(' '),
					annual_revenue: 0,
					source_name: 'shopify_store',
					source_type: Shopify.type,
					tenant_id: null,
					updated_at: new Date().toISOString(),
					user_id: null,
					store_type: Shopify.type,
					store_url: `https://${shop.domain}`,
					support_email: shop.email,
					support_phone: shop.phone,
					company_name: shop.name,
					company_website: `https://${shop.domain}`,
					billing_email: shop.email,
					brand_id: '',
					created_at: new Date(shop.created_at).toISOString(),
					extra_data: {
						installation_source: 'shopify',
						shopify_access_token: accessToken,
						shopify_domain: shop.domain,
						shopify: {
							store: shop,
							session: callbackResponse.session,
						},
					},
				},
			},
		});
	}

	// @ts-expect-error: TODO: fix this
	override async open(args: CommonArgs & { params: AuthParams }) {
		// Initialization
		const sanitizedShop = this.shopify.utils.sanitizeShop(args.params.shop);
		const isValidRequest = await this.shopify.utils.validateHmac(args.params);
		const isInstalled = !!args.params.session;

		// Validation
		if (!sanitizedShop) throw new AppError('Invalid shop provided');
		if (!isValidRequest)
			throw new AppError(
				'The request may not be from Shopify, please contact support if you believe this is an error.'
			);

		if (!isInstalled) {
			console.debug('The app is not installed, so we need to install it');
			return this.auth(args);
		}

		const userRes = await this.accountData.getProviderUser({
			integrationId: Shopify.id,
			lookupId: sanitizedShop,
		});

		if (!userRes.data) {
			console.debug('The app is installed, but no user on our platform, so we onboard them again');
			console.debug({
				userRes,
				lookupId: sanitizedShop,
			});
			return this.auth(args);
		}

		console.debug('The app is installed, and there is a user on our platform, so we can log them in');
		return super.open({
			data: {
				brand: {
					integrationId: Shopify.id,
					lookupId: sanitizedShop,
				},
				user: userRes.data,
			},
		});
	}

	override resource(args: ResourceArgs) {
		if (args.integration.integration_id !== Shopify.id) {
			throw new Error(`Invalid integration id, got ${args.integration.integration_id} expected ${Shopify.id}`);
		}

		return {
			fetch: this.createFetch({
				url: `https://${args.integration.lookup_id}.myshopify.com/admin/api/${LATEST_API_VERSION}`,
				headers: {
					[ShopifyHeader.AccessToken]: args.integration.settings.credentials.access_token,
				},
			}),
		};
	}

	override async handleEvent(props: EventArgs) {
		console.log({ props });
	}

	override async identify(args: IdentifyArgs): Promise<IdentifyReturn> {
		const identity = args?.identity;
		if (!identity) throw new Error('No identity found');

		const username = identity.phone ?? identity.email;
		if (!username) return { contact: null, channels: null };

		if (identity.id) {
			console.debug('TODO: Fetch contact by id from Shopify');
		}

		args.data = {
			contact: {
				brand_id: args.integration.brand_id,
				external_id: identity.id,
				source: Shopify.type,
			},
			channels: [],
		};

		if (identity.email && args.data.channels) {
			args.data.channels.push({
				username: identity.email,
				extra_data: {
					timezone: '',
					source: 'shopify',
				},
			});
		}

		if (identity.phone && args.data.channels) {
			args.data.channels.push({
				username: identity.phone,
				extra_data: {
					timezone: '',
					source: 'shopify',
				},
			});
		}

		const { contact, channels } = await super.identify(args);

		if (contact?.id && identity.cart_id) {
			console.info('Found identity for cart, upsert the cart with the contact');
			const existingCartRes = await this.dataClient
				.from('carts')
				.select('*')
				.eq('external_id', identity.cart_id)
				.eq('brand_integration_id', args.integration.id)
				.maybeSingle();

			console.debug('Existing cart', existingCartRes.data);

			const upsertedCartRes = await this.dataClient
				.from('carts')
				.upsert(
					{
						external_id: identity.cart_id,
						brand_integration_id: args.integration.id,
						created_at: new Date().toISOString(),
						updated_at: new Date().toISOString(),
						state: 'pending',
						extra_data: {
							from_identify: true,
						},
						total: 0,
						...existingCartRes.data,
						contact_id: contact.id,
					},
					{ onConflict: 'brand_integration_id,external_id' }
				)
				.select('id')
				.maybeSingle();

			console.debug('Upserted cart', upsertedCartRes.data);
		}

		return { contact, channels };
	}
}

type AuthParams = {
	hmac: string;
	host: string;
	timestamp: string;
	code: string;
	shop: string;
	session?: string;

	// Internal //
	/** The brand_integrations.id of the integration, used to reauth and update the integration */
	bid?: string;
	/** The leads.id of the integration, used to reauth and update the integration */
	lid?: string;
};

// You can remove or simplify these types as they're now handled by the Shopify API package
export interface LoginEntity {
	access_token: string;
	scope: string;
}

export interface Shop {
	id: number;
	name: string;
	email: string;
	domain: string;
	province: string;
	country: string;
	address1: string;
	zip: string;
	city: string;
	source: string | null;
	phone: string | null;
	latitude: number;
	longitude: number;
	primary_locale: string;
	address2: string;
	created_at: string;
	updated_at: string;
	country_code: string;
	country_name: string;
	currency: string;
	customer_email: string;
	timezone: string;
	iana_timezone: string;
	shop_owner: string;
	money_format: string;
	money_with_currency_format: string;
	weight_unit: string;
	province_code: string;
	taxes_included: boolean;
	auto_configure_tax_inclusivity: boolean | null;
	tax_shipping: boolean | null;
	county_taxes: boolean;
	plan_display_name: string;
	plan_name: string;
	has_discounts: boolean;
	has_gift_cards: boolean;
	myshopify_domain: string;
	google_apps_domain: string | null;
	google_apps_login_enabled: boolean | null;
	money_in_emails_format: string;
	money_with_currency_in_emails_format: string;
	eligible_for_payments: boolean;
	requires_extra_payments_agreement: boolean;
	password_enabled: boolean;
	has_storefront: boolean;
	finances: boolean;
	primary_location_id: number;
	checkout_api_supported: boolean;
	multi_location_enabled: boolean;
	setup_required: boolean;
	pre_launch_enabled: boolean;
	enabled_presentment_currencies: string[];
	marketing_sms_consent_enabled_at_checkout: boolean;
	transactional_sms_disabled: boolean;
}
