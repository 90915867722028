'use client';

import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useContext } from 'react';

import type { TokenEntity } from '@voyage-lab/core-auth';
import { AppError } from '@voyage-lab/core-common';

import type { AuthSession } from '../auth';
import { auth, signOut, updateAuth } from '../auth';

const AUTH_LIFE_TIME = 1000 * 6 * 5;
const AUTH_REFETCH_INTERVAL = 10 * 1000;

const AuthContext = createContext<{
	authQuery: UseQueryResult<AuthSession>;
	updateAuthMutation: UseMutationResult<Partial<TokenEntity> | undefined, unknown, Partial<TokenEntity>, unknown>;
	signOutMutation: UseMutationResult<void, unknown, string | undefined, unknown>;
}>(null!);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
	const queryClient = useQueryClient();

	const authQuery = useQuery({
		queryKey: ['auth'],
		queryFn: async () => {
			const session = await auth({ optional: true, includeProfile: true });
			if (!session) throw new AppError('Unauthenticated', 'UnauthenticatedError');
			return session;
		},
		refetchInterval: AUTH_REFETCH_INTERVAL,
		staleTime: AUTH_LIFE_TIME,
		cacheTime: AUTH_LIFE_TIME,
	});

	const signOutMutation = useMutation({
		mutationFn: async (redirectUrl?: string) => {
			await queryClient.cancelQueries();
			await signOut({ redirectUrl });
			await queryClient.resetQueries();
		},
	});

	const updateAuthMutation = useMutation({
		mutationFn: async (token: Partial<TokenEntity>) => {
			const updatedAuth = await updateAuth(token);
			await authQuery.refetch();

			queryClient.refetchQueries();
			return updatedAuth;
		},
	});

	return (
		<AuthContext.Provider
			value={{
				authQuery,
				updateAuthMutation,
				signOutMutation,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export function useAuthContext() {
	const context = useContext(AuthContext);
	if (!context) throw new Error('useAuthContext must be used within a AuthContextProvider');
	return context;
}
